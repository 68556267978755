/*! CSS Used from: https://uploads-ssl.webflow.com/5efc669c581d3b4a35e28a40/css/trackingapp.webflow.2f49d9d31.css */
strong {
    font-weight: bold;
}

img {
    border: 0;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.w-layout-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 5px;
    grid-column-gap: 16px;
}



.trackitem {
    position: static;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 15px;
    border: 1px solid #ffffff !important;
    border-radius: 8px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: box-shadow 150ms cubic-bezier(.23, 1, .32, 1);
    transition: box-shadow 150ms cubic-bezier(.23, 1, .32, 1);
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    will-change: auto;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */


}

.trackitem:hover {

    border: 1px solid #dddddd !important;
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.15);

}

.trackitemheader {
    display: block;
    height: 26px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 1px solid #eaeaea;
    background-color: #FCFCFC;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;


}

.trackitembody {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding: 3px 0px;
    border-radius: 0px 0px 8px 8px;
    font-size: 14px;
    -webkit-transition: box-shadow 150ms cubic-bezier(.23, 1, .32, 1), border-radius 1s;
    transition: box-shadow 150ms cubic-bezier(.23, 1, .32, 1), border-radius 1s;
}



.trackitembody.trackitemexpanded:hover {
    border-radius: 0px;
    -webkit-transition: box-shadow 150ms cubic-bezier(.23, 1, .32, 1), border-radius 1s;
    transition: box-shadow 150ms cubic-bezier(.23, 1, .32, 1), border-radius 1s;
}

.trackitemtag {
    position: static;
    top: 4px;
    display: inline-block;
    overflow: visible;
    height: 18px;
    padding-top: 0px;
    padding-right: 3px;
    padding-left: 3px;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid transparent;
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 3px;
}

.trackitemtagtext {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.4;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

}

.trackitemtag.static {
    background-color: #f1f1f1;
    color: #696969;
    border: 1px solid transparent;
}

.trackitemtag.lightBlue {
    background-color: #0b3b68;
    color: #0d6aad;
}

.trackitemtag.itempadding {
    margin-left: 8px;
    background-color: #01d2fc;
}

.tagicon {
    position: relative;
    top: -2px;
    width: 14px;
    height: 14px;
    margin-top: 0px;
    margin-right: 1px;
    padding-right: 0px;
    background-color: transparent;
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.shippingstateiconcontainer {
    position: static;
    left: 4px;
    top: 2px;
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-radius: 7px;

}

.stateIcon-Green {
    background-image: linear-gradient(340deg, #47B881, #47B881);
}

.stateIcon-Purple {
    background-image: linear-gradient(340deg, #735DD0, #735DD0);
}

.stateIcon-Blue {
    background-image: linear-gradient(340deg, #007DF6, #007DF6);
}
.stateIcon-Ghost {
    background-image: linear-gradient(340deg, #f3f3f3, #f3f3f3);

}

.stateIcon-Yellow {
    background-image: linear-gradient(340deg, #F7D154, #F7D154);
}

.stateIcon-Gray {
    background-image: linear-gradient(340deg, #425A70, #425A70);
}

.stateIcon-Orange {
    background-image: linear-gradient(340deg, #D9822B, #D9822B);
}

.stateIcon-Red {
    background-image: linear-gradient(340deg, #EC4C47, #EC4C47);
}

.shippingstateicon {
    position: relative;
    top: 7px;
    left: 7px;

    color: #ffffff;
}

.trackinginfo {
    height: 43px;
    margin-top: 2px;
    margin-left: 15px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
}

.div-block-195 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 20px;
    line-height: 1.4;
    font-weight: 500;
}

.div-block-196 {
    height: 20px;
    line-height: 1.4;
}

.div-block-197 {
    position: relative;
    top: 7px;
    width: 8px;
    height: 8px;
    padding-top: 0px;
    border-radius: 4px;
    background-color: #36a4ff;
}

.trackingstatetext {

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #1c1e21;
    font-weight: 500;
    white-space: nowrap;
}

.trackingnrtext {
    text-overflow: ellipsis;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #1c1e21;
    font-weight: 700;
    cursor: pointer;
}

.oridesblock {
    display: -ms-grid;
    display: grid;
    width: 100%;
    height: auto;
    padding: 2px 7px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 2px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 12px auto 15px;
    grid-template-rows: 12px auto 15px;
    border-radius: 8px;
//background-color: hsla(0, 0%, 78.8%, 0.1);
//background-color: #ffffff;
    -webkit-transition: background-color 150ms cubic-bezier(.23, 1, .32, 1);
    transition: background-color 150ms cubic-bezier(.23, 1, .32, 1);
    cursor: default;
}

.oridesblock:hover {
//background-color: hsla(0, 0%, 78.8%, 0.3);
    background-color: hsla(0, 0%, 92%, 0.3);
}

.oridesblock.alignright {
    display: -ms-grid;
    display: grid;
    height: 100%;
    padding: 2px 7px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 2px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 12px auto 15px;
    grid-template-rows: 12px auto 15px;
    -webkit-transition: background-color 150ms cubic-bezier(.23, 1, .32, 1);
    transition: background-color 150ms cubic-bezier(.23, 1, .32, 1);
    text-align: right;
    cursor: default;
}

.oridesblock.alignright:hover {
//background-color: hsla(0, 0%, 78.8%, 0.3);
}

.oridesdirectioncontainer {
    color: #7b88a8;
    font-size: 11px;
}

.oridescountrycontainer {
    overflow: hidden;
    line-height: 1.2;
    font-weight: 500;
}

.oridescarriercontainer {
    color: #3578e5;
    font-weight: 300;
    white-space: nowrap;
}

.lastrecordcontainer {
    width: 100%;
    height: auto;
}

.trackinfocontainer {
    position: static;
    display: -ms-grid;
    display: grid;
    height: 100%;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 6px;
    grid-row-gap: 16px;
    -ms-grid-columns: auto 30px auto;
    grid-template-columns: auto 30px auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -o-object-fit: fill;
    object-fit: fill;
}

.grid-2 {
    height: 28px;
    justify-items: start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-columns: 15fr 1fr;
    grid-template-columns: 15fr 1fr;
}

.tagcontainer {
    overflow: hidden;
    width: 100%;
    height: 28px;
    padding-top: 3px;
    padding-left: 10px;
}

.controllcontainer {
    margin-right: 3px;
    margin-bottom: 2px;
    height: 22px;
    text-align: right;
    border-radius: 6px;
    color: #414141;
}

.controllcontainer:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}


.image-33 {
    width: 18px;
}

.lastrecordheadercontainer {
    display: -ms-grid;
    display: grid;
    width: auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: auto 1fr;
    grid-template-columns: auto 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.grid-3 {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
    grid-column-gap: 3px;
    -ms-grid-columns: minmax(300px, 0.75fr) minmax(401px, 0.75fr) 3fr;
    grid-template-columns: minmax(300px, 0.75fr) minmax(401px, 0.75fr) 3fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.itemstatuscontainer {
    text-overflow: ellipsis;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.recordcontainer {
    display: -ms-grid;
    display: grid;
    width: 100%;
    margin-left: 15px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 40px;
    grid-template-columns: 1fr 40px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.trackingdirection {
    width: 25px;

}

.lastrecordbody {
    /*margin-top: 5px;*/
}

.image-36 {

    color: hsla(0, 0%, 78.8%, 0.5);


}

.expandcontrol {
    width: 40px;
    margin-right: 5px;
    -webkit-filter: invert(25%);
    filter: invert(25%);
    text-align: center;
    cursor: pointer;
}

.lastrecorddate {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.lastrecordmap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 20px;
    background-color: transparent;
}

.lastrecordtext {
    margin-right: 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #7b88a8;
    line-height: 1.3;
}

.lastrecorddatetag {
    height: 20px;
    padding: 1px 3px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border: 0px none transparent;
    border-radius: 4px;
    background-color: transparent;
    color: #7b88a8;
    line-height: 1.2;
}

.image-37 {
    width: 14px;
    margin-left: 10px;
}

.trackingmaptagtext {
    width: 100%;
    padding-right: 4px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #7c7c7c;
    font-weight: 400;
    white-space: nowrap;
}

.maptag {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 20px;
    padding-right: 4px;
    padding-left: 4px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    border-radius: 4px;
    background-color: hsla(0, 0%, 78.8%, 0.1);
    -webkit-transition: background-color 150ms cubic-bezier(.23, 1, .32, 1);
    transition: background-color 150ms cubic-bezier(.23, 1, .32, 1);
}



.lastmessagetext {
    /*margin-top: 4px;*/
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    color:#1c1e21 !important;
}

.lastrecorddatetext {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #1c1e21;
    font-weight: 500;
    white-space: nowrap;
}

.bold-text-2 {
    color: #1c1e21;
    font-weight: 600;
}

.chevron {
    width: 15px;
    text-align: center;
    -webkit-transition: -webkit-transform 0.15s;
    -moz-transition: -moz-transform 0.15s;
    -ms-transition: -ms-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform 0.15s;
}

.text-block-92 {
    color: #1c1e21;
}

.text-block-93 {
    color: #1c1e21;
}

.trackitemtimeline {
    display: block;
    height: auto;
    width: calc(100% - 4px);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
    background-color: #f5f7fa;
    box-shadow: inset 2px 2px 4px 0px rgb(0 0 0 / 20%), inset -1px -1px 5px 0px rgb(0 0 0 / 9%);;
    -webkit-transition: box-shadow 150ms cubic-bezier(.23, 1, .32, 1);
    transition: box-shadow 150ms cubic-bezier(.23, 1, .32, 1);
}

.trackitemtimeline:hover {
    border-color: transparent;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #f8f8f8;
}

.timelinecontrolscontainer {
    position: relative;
    z-index: 2;
    width: 100%;
}


@media screen and (max-width: 1564px) {
    .trackitem {
        border: 2px none transparent;
    }

    .trackitemtag.itempadding {
        box-shadow: none;
    }

    .trackingnrtext {
        font-weight: 700;
    }

    .trackinfocontainer {
        height: auto;
        -ms-grid-columns: auto 40px 1fr;
        grid-template-columns: auto 40px 1fr;
    }

    .grid-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
        grid-row-gap: 3px;
        -ms-grid-columns: 0.75fr 1fr;
        grid-template-columns: 0.75fr 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
    }

    .recordcontainer {
        margin-left: 0px;
    }

    .expandcontrol {
        width: 30px;
        text-align: center;
        cursor: pointer;
    }


    .chevron {
        width: 15px;
        line-height: 1;
        text-align: center;
    }
    .trackingnrtext {
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis !important;
    }
}

@media screen and (max-width: 767px) {
    .trackinfocontainer {
        -ms-grid-columns: 1fr 30px 1fr;
        grid-template-columns: 1fr 30px 1fr;
    }

    .grid-3 {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto;
    }

    .trackingmaptagtext {
        width: 100%;
    }

    .maptag {
        width: 100%;
    }

}

@media screen and (max-width: 479px) {
    .trackitemheader {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .trackitemtag {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-right: 5px;
    }

    .tagicon {
        margin-top: auto;
    }

    .trackinfocontainer {
        height: auto;
        -ms-grid-columns: auto auto auto;
        grid-template-columns: auto auto auto;
    }

    .grid-2 {
        width: 100%;
        -webkit-box-pack: start;
        -webkit-justify-content: start;
        -ms-flex-pack: start;
        justify-content: start;
        -webkit-align-content: start;
        -ms-flex-line-pack: start;
        align-content: start;
        -ms-grid-columns: 5fr 1fr;
        grid-template-columns: 5fr 1fr;
        -ms-grid-rows: auto;
        grid-template-rows: auto;
    }

    .tagcontainer {
        margin-top: 3px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .controllcontainer {
        padding-right: 10px;
        text-align: right;
    }

    .image-33 {
        width: 18px;
        -webkit-filter: invert(40%);
        filter: invert(40%);
        text-align: left;
    }

    .lastrecordheadercontainer {
        grid-row-gap: 3px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }

    .grid-3 {

        grid-row-gap: 3px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto;

    }

    .recordcontainer {
        -ms-grid-columns: 1fr auto;
        grid-template-columns: 1fr auto;
        grid-column-gap: 0px;
    }
    .trackingnrtext {
        max-width: 220px;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis !important;
    }


}

#w-node-02e438ee93fa-43cb7079 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: start;
    justify-self: start;
}

#w-node-02e438ee9401-43cb7079 {
    -ms-grid-column-align: end;
    justify-self: end;
}

#w-node-02e438ee9410-43cb7079 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-02e438ee9414-43cb7079 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-02e438ee9417-43cb7079 {
    -webkit-align-self: end;
    -ms-flex-item-align: end;
    -ms-grid-row-align: end;
    align-self: end;
}

#w-node-02e438ee941a-43cb7079 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: center;
    justify-self: center;
}

#w-node-02e438ee9420-43cb7079 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-02e438ee9423-43cb7079 {
    -webkit-align-self: end;
    -ms-flex-item-align: end;
    -ms-grid-row-align: end;
    align-self: end;
}

#w-node-02e438ee9426-43cb7079 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: start;
    justify-self: start;
}

#w-node-02e438ee9429-43cb7079 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

#w-node-02e438ee9438-43cb7079 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: center;
    justify-self: center;
}

@media screen and (max-width: 1564px) {
    #w-node-02e438ee9426-43cb7079 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
    }

    #w-node-02e438ee9438-43cb7079 {
        -ms-grid-column-align: end;
        justify-self: end;
    }
}

@media screen and (max-width: 767px) {
    #w-node-02e438ee9426-43cb7079 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
    }
}

@media screen and (max-width: 479px) {
    #w-node-02e438ee93fa-43cb7079 {
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        -ms-grid-row-align: auto;
        align-self: auto;
        -ms-grid-column-align: auto;
        justify-self: auto;
    }
}

.pull-right {
    float: right !important;
}

.input-group {
}

.controllsButton {

    margin-bottom: 3px;
    border-radius: 4px;

}

.controlsLanguageSelect {

    margin-bottom: 5px;
    border-radius: 4px;
}


.controlsTranslateContainer {
    position: relative;
    border-collapse: separate;
    display: inline-block;
    line-height: 24px;

}

.carrierSelector {
    z-index: 4;
    float: right;
    display: inline-block;
    /* position: absolute; */
    width: auto;
    margin-top: 10px;
    right: 45px;
    font-size: 14px;
}

.badge-clickable {
    cursor: pointer;
}

.mapContainer {
    position: relative;
}


.mapCloseContainer {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 21px;
    height: 21px;
    margin-right: 0;
    margin-left: auto;
    background-color: white;
    z-index: 1000;
    justify-content: center;
    border-radius: 4px;
    color:#1c1e21;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    cursor: pointer;
}

.tackItemOptionsContainer{
    list-style: none;
}
.item_options_button{
    color:#1c1e21;
    text-align: left;

    margin-left: 4px;
    margin-right: 3px;

    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;

    line-height: 22px;
    border-radius: 4px;
    font-weight: 600;

    white-space: nowrap;
    cursor: pointer;
}




























