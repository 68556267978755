.CDT-TimeLineContainer {
    margin-left: 6px;
    margin-right: 6px;
    z-index: 9;
    min-height: 30px;
}

.CDT-TimeLineInnerContainer {
    margin-left: 10px;
}

.CDT-TimeLineItemContainer {

    margin-left: 15px;
    display: inline-block;
    z-index: 10;
    cursor: default;
    border: 1px solid #dedede;
    border-radius: 3px;
    /*box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);*/
}
.hasInfo:hover{
    /* box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);*/

}

.CheckPointSelected{
    border: 1px solid #a9a9a9;
}

/*
.mark-info::before {
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgb(16, 112, 202);
}

.mark-success::before {
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgb(21, 128, 252);
}*/

.mark-last {
    border: 2px solid rgba(21, 128, 252,.5);
}



.timeline {
    position: relative;
}

.timeline:before {
    position: absolute;
    content: '';
    width: 3px;
    height: 100%;
    background: #1580fc;
    left: 46px;
    top: 0px;
    border-radius: 0px 0px 2px 2px;

}


.timeline-section {
    padding-left: 50px;
    display: block;
    position: relative;
    padding-top: 0px;
    height: auto;

}

.timeline-section-day {
    height: 28px;
    padding-left: 35px;
    display: block;
    position: relative;


}

.timeline-section-day-top {
    height: 38px;
    padding-left: 35px;
    display: block;
    position: relative;

    padding-top: 10px;
}

.timeline-end {
    padding-left: 50px;
    display: block;
    position: relative;

    padding-top: 10px;
}

.timeline-date {


    line-height: 22px;
    font-weight: 500;
    font-size: 12px;
    padding: 0px 5px;
    position: absolute;
    left: 0px;


    color: #1580fc;
}

.timeline-day {

    width: 96px;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    padding: 0px 5px;
    position: absolute;
    left: 0px;
    color: #ffffff;
    background-color: #1580fc;
}

/*  connection line */
.timeline-section:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 2px;
    background-color: #e5e4e4;
    border-radius: 1px;
    top: 0.68pc;
    left: 55px;
}

/* timeline dot */
.timeline-section:after {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    background: #1580fc;
    top: 0.4pc;
    left: 42px;
    border: 2px solid #f5f7fa;

    border-radius: 100%;
}

.timeline-end:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background: rgba(255, 255, 255, 0);
    left: 44px;
    border: 2px solid #1580fc;
    border-radius: 100%;
}

.checkPointContainer {
    display: inline-block;

}

.checkpointHeader {
    width: 100%;
    position: relative;
    border-collapse: separate;
    display: inline-block;
    white-space: nowrap;
}

.timeline-section .col-sm-4 {
    margin-bottom: 15px;
}

.checkpointPositionTag {

    line-height: 22px;

    float: right;
    margin-left: 8px;
    margin-right: 8px;

    color: #9d9d9d;

}

.checkpointCarrierTag {
    background-color: #f5f5f5;
    border-radius: 4px;
    float: right;
    margin-left: 4px;
    padding-left: 4px;
    padding-right: 4px;
}

.checkpointMessageContainer {
    position: relative;
}

.checkpointMessage {
    /* display: inline-block; */
    padding-left: 6px;
    padding-right: 6px;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: #191919;
    background: #ffffff;
    /* box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);*/
    width: 100%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    line-height: 22px;
}


.checkPointInfoContainer {
    float: right;
}

.checkPointInfo {
    background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 220, .5) 0%, transparent 100%);
    background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 220, .5) 0%, transparent 100%);
    background-image: -o-linear-gradient(-45deg, rgba(255, 255, 220, .5) 0%, transparent 100%);
    background-image: -ms-linear-gradient(-45deg, rgba(255, 255, 220, .5) 0%, transparent 100%);
    border-radius: 100%;
    border: 1px solid #cdcdcd;
    width: 8px;
    height: 8px;
    margin-right: 2px;
    float: left;
}


.checkPointDetailsContainer {

    font-weight: 400;
    font-size: 13px;
    border-top: 1px solid #dbdbdb;
}

.checkPointDetailsCarrierDesc {

}

.checkPointDetailsTypeCard {

    height:20px;
    line-height: 18px;
    font-size: 12px;
    white-space: nowrap;

    padding-left:4px;
    padding-right:4px;
    border-radius: 4px;
    margin-top: 2px;

    box-sizing: border-box;
    float: left;
}
.TypeTransfer{
    border: 1px #e2e2e2  solid;
    background-color: #fcfcfc;
    color:#434343
}
.TypeLocation{
    color: #434343;
}

.TypeDelivery{
    border: 1px #e2e2e2  solid;
    background-color: rgba(191, 253, 151,0.2);
    color:#434343;
}
.TypeOutForDelivery{
    border: 1px #e2e2e2  solid;
    background-color: rgba(151, 188, 253,0.2);
    color:#434343;
}

.TypeException{
    border: 1px #e2e2e2  solid;
    background-color: rgba(253, 151, 151,0.2);
    color:#434343;
}

.checkPointDetailsCarrierInfoContainer {
    float: left;
    font-size: 12px;
    line-height: 18px;
    height: 20px;
    margin-right: 4px;
    margin-top: 2px;
    padding-left: 24px;
    background-color: #ffffff;

    padding-right: 4px;
    border-radius: 4px;
    color: #434343;
    border: 1px #e2e2e2 solid;
    filter: opacity(0.9);
    transition: all 0.2s ease-in-out;
}

.checkPointDetailsCarrierInfoContainer:hover {
    filter: opacity(1);
}

.carrierLogoSmall {

    position: absolute;
    top: 1px;
    background-size: cover;
    background-repeat: no-repeat;
    left: 1px;
    bottom: 1px;
    width: 16px;
    /* height: 100%; */
    border-radius: 3px;

}

.carrierLogoSmall:hover {

    filter: opacity(1);
}


.transferInfo {
    float: left;
    font-size: 13px;
}

.transferLocation {
    margin-left: 4px;
    font-size: 13px;
    float: right;
    font-weight: 600;
    color: #6d6d6d;
}
.TypeInfoRow {
    padding: 0px 4px;
    height: 24px;
}
.LocationInfoRow{
    padding-left: 6px;
    padding-right: 6px;

}






