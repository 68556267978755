html, body, #root{
    height: 100%
}

body .ps-container > .ps-scrollbar-y-rail .ps-scrollbar-y {
    transition: none;
}

.RootContainer {
    height:100%;
}

.MainContainer {

    background-position: center;
    background-size: auto;
    background-repeat: repeat;
    background: #efefef;
display: flex;
    height:100%;
    overflow: hidden;

}
.TrackItemTopSpacer{
    height:10px;
}

.TrackItemBottomSpacer{
    height:90px;
}

.TrackitemContainer {
    position: absolute;
    z-index:1;
    padding-top:0px;
    padding-bottom:0px;
    padding-right:2px;
    left:10px;
    bottom: 0px;
    top:46px;
    right:410px;
    overflow-x: visible;

}
.mobileScroll{
    overflow-y:scroll;
}

.NumberContainer {

}
.NumberContainer.wide {
    margin-left: auto;
    margin-right: auto;
    max-width: 970px;
    width: 100%;
    padding-left:5px;
    padding-right:5px;
}

.NumberContainerCard {
    padding:8px;
    top:56px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
    float:right;
}
.NumberContainerCard.small {
    position: absolute;
    right: 10px;
    width: 400px;
}

.NumberContainerCard.wide {
    position: relative;
    height: 283px;
    width: 100%;
}

.AdContainerCard {
    position: absolute;
    padding: 8px;
    top: 350px;
    right: 10px;
    width: 400px;
    min-height: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
    float: right;
}

.inFlowAd {
    width: 100%;
    margin-right: 15px;
    margin-left: 5px;
    min-height: 50px
}

.inFlowAdContainer {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 1040px) {
    .TrackitemContainer {
        position: absolute;
        z-index: 1;
        padding-top: 5px;
        padding-left: 5px;
        padding-bottom: 0px;
        padding-right: 0px;
        left: 0px;
        bottom: 0px;
        top:42px;
        right:0px;
        overflow-x: visible;
    }

}
