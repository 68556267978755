@geekblue-1: #f0f5ff;
@geekblue-2: #d6e4ff;
@geekblue-3: #adc6ff;
@geekblue-4: #85a5ff;
@geekblue-5: #597ef7;
@geekblue-6: #2f54eb;
@geekblue-7: #1d39c4;
@geekblue-8: #10239e;
@geekblue-9: #061178;
@geekblue-10: #030852;

@purple-1: #f9f0ff;
@purple-2: #efdbff;
@purple-3: #d3adf7;
@purple-4: #b37feb;
@purple-5: #9254de;
@purple-6: #722ed1;
@purple-7: #531dab;
@purple-8: #391085;
@purple-9: #22075e;
@purple-10: #120338;

@magenta-1: #fff0f6;
@magenta-2: #ffd6e7;
@magenta-3: #ffadd2;
@magenta-4: #ff85c0;
@magenta-5: #f759ab;
@magenta-6: #eb2f96;
@magenta-7: #c41d7f;
@magenta-8: #9e1068;
@magenta-9: #780650;
@magenta-10: #520339;

@green-1: #f6ffed;
@green-2: #d9f7be;
@green-3: #b7eb8f;
@green-4: #95de64;
@green-5: #73d13d;
@green-6: #52c41a;
@green-7: #389e0d;
@green-8: #237804;
@green-9: #135200;
@green-10: #092b00;

@cyan-1: #e6fffb;
@cyan-2: #b5f5ec;
@cyan-3: #87e8de;
@cyan-4: #5cdbd3;
@cyan-5: #36cfc9;
@cyan-6: #13c2c2;
@cyan-7: #08979c;
@cyan-8: #006d75;
@cyan-9: #00474f;
@cyan-10: #002329;

@blue-1: #e6f7ff;
@blue-2: #bae7ff;
@blue-3: #91d5ff;
@blue-4: #69c0ff;
@blue-5: #40a9ff;
@blue-6: #1890ff;
@blue-7: #096dd9;
@blue-8: #0050b3;
@blue-9: #003a8c;
@blue-10: #002766;

@gold-1: #fffbe6;
@gold-2: #fff1b8;
@gold-3: #ffe58f;
@gold-4: #ffd666;
@gold-5: #ffc53d;
@gold-6: #faad14;
@gold-7: #d48806;
@gold-8: #ad6800;
@gold-9: #874d00;
@gold-10: #613400;

@yellow-1: #feffe6;
@yellow-2: #ffffb8;
@yellow-3: #fffb8f;
@yellow-4: #fff566;
@yellow-5: #ffec3d;
@yellow-6: #fadb14;
@yellow-7: #d4b106;
@yellow-8: #ad8b00;
@yellow-9: #876800;
@yellow-10: #614700;

@lime-1: #fcffe6;
@lime-2: #f4ffb8;
@lime-3: #eaff8f;
@lime-4: #d3f261;
@lime-5: #bae637;
@lime-6: #a0d911;
@lime-7: #7cb305;
@lime-8: #5b8c00;
@lime-9: #3f6600;
@lime-10: #254000;

@red-1: #fff1f0;
@red-2: #ffccc7;
@red-3: #ffa39e;
@red-4: #ff7875;
@red-5: #ff4d4f;
@red-6: #f5222d;
@red-7: #cf1322;
@red-8: #a8071a;
@red-9: #820014;
@red-10: #5c0011;

@volcano-1: #fff2e8;
@volcano-2: #ffd8bf;
@volcano-3: #ffbb96;
@volcano-4: #ff9c6e;
@volcano-5: #ff7a45;
@volcano-6: #fa541c;
@volcano-7: #d4380d;
@volcano-8: #ad2102;
@volcano-9: #871400;
@volcano-10: #610b00;

@orange-1: #fff7e6;
@orange-2: #ffe7ba;
@orange-3: #ffd591;
@orange-4: #ffc069;
@orange-5: #ffa940;
@orange-6: #fa8c16;
@orange-7: #d46b08;
@orange-8: #ad4e00;
@orange-9: #873800;
@orange-10: #612500;

@gray-1: #ffffff;
@gray-2: #fafafa;
@gray-3: #f5f5f5;
@gray-4: #f0f0f0;
@gray-5: #d9d9d9;
@gray-6: #bfbfbf;
@gray-7: #8c8c8c;
@gray-8: #595959;
@gray-9: #434343;
@gray-10: #262626;
@gray-11: #1f1f1f;
@gray-12: #141414;
@gray-13: #000000;


.bg-gray-1{ background-color: #ffffff;}
.bg-gray-2{ background-color: #fafafa;}
.bg-gray-3{ background-color: #f5f5f5;}
.bg-gray-4{ background-color: #f0f0f0;}
.bg-gray-5{ background-color: #d9d9d9;}
.bg-gray-6{ background-color: #bfbfbf;}
.bg-gray-7{ background-color: #8c8c8c;}
.bg-gray-8{ background-color: #595959;}
.bg-gray-9{ background-color: #434343;}
.bg-gray-10{ background-color: #262626;}
.bg-gray-11{ background-color: #1f1f1f;}
.bg-gray-12{ background-color: #141414;}
.bg-gray-13{ background-color: #000000;}


.bg-geekblue-1{ background-color: #f0f5ff;}
.bg-geekblue-2{ background-color: #d6e4ff;}
.bg-geekblue-3{ background-color: #adc6ff;}
.bg-geekblue-4{ background-color: #85a5ff;}
.bg-geekblue-5{ background-color: #597ef7;}
.bg-geekblue-6{ background-color: #2f54eb;}
.bg-geekblue-7{ background-color: #1d39c4;}
.bg-geekblue-8{ background-color: #10239e;}
.bg-geekblue-9{ background-color: #061178;}
.bg-geekblue-10{ background-color: #030852;}

.bg-purple-1{ background-color: #f9f0ff;}
.bg-purple-2{ background-color: #efdbff;}
.bg-purple-3{ background-color: #d3adf7;}
.bg-purple-4{ background-color: #b37feb;}
.bg-purple-5{ background-color: #9254de;}
.bg-purple-6{ background-color: #722ed1;}
.bg-purple-7{ background-color: #531dab;}
.bg-purple-8{ background-color: #391085;}
.bg-purple-9{ background-color: #22075e;}
.bg-purple-10{ background-color: #120338;}

.bg-magenta-1{ background-color: #fff0f6;}
.bg-magenta-2{ background-color: #ffd6e7;}
.bg-magenta-3{ background-color: #ffadd2;}
.bg-magenta-4{ background-color: #ff85c0;}
.bg-magenta-5{ background-color: #f759ab;}
.bg-magenta-6{ background-color: #eb2f96;}
.bg-magenta-7{ background-color: #c41d7f;}
.bg-magenta-8{ background-color: #9e1068;}
.bg-magenta-9{ background-color: #780650;}
.bg-magenta-10{ background-color: #520339;}

.bg-green-1{ background-color: #f6ffed;}
.bg-green-2{ background-color: #d9f7be;}
.bg-green-3{ background-color: #b7eb8f;}
.bg-green-4{ background-color: #95de64;}
.bg-green-5{ background-color: #73d13d;}
.bg-green-6{ background-color: #52c41a;}
.bg-green-7{ background-color: #389e0d;}
.bg-green-8{ background-color: #237804;}
.bg-green-9{ background-color: #135200;}
.bg-green-10{ background-color: #092b00;}

.bg-cyan-1{ background-color: #e6fffb;}
.bg-cyan-2{ background-color: #b5f5ec;}
.bg-cyan-3{ background-color: #87e8de;}
.bg-cyan-4{ background-color: #5cdbd3;}
.bg-cyan-5{ background-color: #36cfc9;}
.bg-cyan-6{ background-color: #13c2c2;}
.bg-cyan-7{ background-color: #08979c;}
.bg-cyan-8{ background-color: #006d75;}
.bg-cyan-9{ background-color: #00474f;}
.bg-cyan-10{ background-color: #002329;}

.bg-blue-1{ background-color: #e6f7ff;}
.bg-blue-2{ background-color: #bae7ff;}
.bg-blue-3{ background-color: #91d5ff;}
.bg-blue-4{ background-color: #69c0ff;}
.bg-blue-5{ background-color: #40a9ff;}
.bg-blue-6{ background-color: #1890ff;}
.bg-blue-7{ background-color: #096dd9;}
.bg-blue-8{ background-color: #0050b3;}
.bg-blue-9{ background-color: #003a8c;}
.bg-blue-10{ background-color: #002766;}

.bg-gold-1{ background-color: #fffbe6;}
.bg-gold-2{ background-color: #fff1b8;}
.bg-gold-3{ background-color: #ffe58f;}
.bg-gold-4{ background-color: #ffd666;}
.bg-gold-5{ background-color: #ffc53d;}
.bg-gold-6{ background-color: #faad14;}
.bg-gold-7{ background-color: #d48806;}
.bg-gold-8{ background-color: #ad6800;}
.bg-gold-9{ background-color: #874d00;}
.bg-gold-10{ background-color: #613400;}

.bg-yellow-1{ background-color: #feffe6;}
.bg-yellow-2{ background-color: #ffffb8;}
.bg-yellow-3{ background-color: #fffb8f;}
.bg-yellow-4{ background-color: #fff566;}
.bg-yellow-5{ background-color: #ffec3d;}
.bg-yellow-6{ background-color: #fadb14;}
.bg-yellow-7{ background-color: #d4b106;}
.bg-yellow-8{ background-color: #ad8b00;}
.bg-yellow-9{ background-color: #876800;}
.bg-yellow-10{ background-color: #614700;}

.bg-lime-1{ background-color: #fcffe6;}
.bg-lime-2{ background-color: #f4ffb8;}
.bg-lime-3{ background-color: #eaff8f;}
.bg-lime-4{ background-color: #d3f261;}
.bg-lime-5{ background-color: #bae637;}
.bg-lime-6{ background-color: #a0d911;}
.bg-lime-7{ background-color: #7cb305;}
.bg-lime-8{ background-color: #5b8c00;}
.bg-lime-9{ background-color: #3f6600;}
.bg-lime-10{ background-color: #254000;}

.bg-red-1{ background-color: #fff1f0;}
.bg-red-2{ background-color: #ffccc7;}
.bg-red-3{ background-color: #ffa39e;}
.bg-red-4{ background-color: #ff7875;}
.bg-red-5{ background-color: #ff4d4f;}
.bg-red-6{ background-color: #f5222d;}
.bg-red-7{ background-color: #cf1322;}
.bg-red-8{ background-color: #a8071a;}
.bg-red-9{ background-color: #820014;}
.bg-red-10{ background-color: #5c0011;}

.bg-volcano-1{ background-color: #fff2e8;}
.bg-volcano-2{ background-color: #ffd8bf;}
.bg-volcano-3{ background-color: #ffbb96;}
.bg-volcano-4{ background-color: #ff9c6e;}
.bg-volcano-5{ background-color: #ff7a45;}
.bg-volcano-6{ background-color: #fa541c;}
.bg-volcano-7{ background-color: #d4380d;}
.bg-volcano-8{ background-color: #ad2102;}
.bg-volcano-9{ background-color: #871400;}
.bg-volcano-10{ background-color: #610b00;}

.bg-orange-1{ background-color: #fff7e6;}
.bg-orange-2{ background-color: #ffe7ba;}
.bg-orange-3{ background-color: #ffd591;}
.bg-orange-4{ background-color: #ffc069;}
.bg-orange-5{ background-color: #ffa940;}
.bg-orange-6{ background-color: #fa8c16;}
.bg-orange-7{ background-color: #d46b08;}
.bg-orange-8{ background-color: #ad4e00;}
.bg-orange-9{ background-color: #873800;}
.bg-orange-10{ background-color: #612500;}

.vurox-color-gray-1{ color: #ffffff !important;}
.vurox-color-gray-2{ color: #fafafa !important;}
.vurox-color-gray-3{ color: #f5f5f5 !important;}
.vurox-color-gray-4{ color: #f0f0f0 !important;}
.vurox-color-gray-5{ color: #d9d9d9 !important;}
.vurox-color-gray-6{ color: #bfbfbf !important;}
.vurox-color-gray-7{ color: #8c8c8c !important;}
.vurox-color-gray-8{ color: #595959 !important;}
.vurox-color-gray-9{ color: #434343 !important;}
.vurox-color-gray-10{ color: #262626 !important;}
.vurox-color-gray-11{ color: #1f1f1f !important;}
.vurox-color-gray-12{ color: #141414 !important;}
.vurox-color-gray-13{ color: #000000 !important;}


.vurox-color-geekblue-1{ color: #f0f5ff !important;}
.vurox-color-geekblue-2{ color: #d6e4ff !important;}
.vurox-color-geekblue-3{ color: #adc6ff !important;}
.vurox-color-geekblue-4{ color: #85a5ff !important;}
.vurox-color-geekblue-5{ color: #597ef7 !important;}
.vurox-color-geekblue-6{ color: #2f54eb !important;}
.vurox-color-geekblue-7{ color: #1d39c4 !important;}
.vurox-color-geekblue-8{ color: #10239e !important;}
.vurox-color-geekblue-9{ color: #061178 !important;}
.vurox-color-geekblue-10{ color: #030852 !important;}

.vurox-color-purple-1{ color: #f9f0ff !important;}
.vurox-color-purple-2{ color: #efdbff !important;}
.vurox-color-purple-3{ color: #d3adf7 !important;}
.vurox-color-purple-4{ color: #b37feb !important;}
.vurox-color-purple-5{ color: #9254de !important;}
.vurox-color-purple-6{ color: #722ed1 !important;}
.vurox-color-purple-7{ color: #531dab !important;}
.vurox-color-purple-8{ color: #391085 !important;}
.vurox-color-purple-9{ color: #22075e !important;}
.vurox-color-purple-10{ color: #120338 !important;}

.vurox-color-magenta-1{ color: #fff0f6 !important;}
.vurox-color-magenta-2{ color: #ffd6e7 !important;}
.vurox-color-magenta-3{ color: #ffadd2 !important;}
.vurox-color-magenta-4{ color: #ff85c0 !important;}
.vurox-color-magenta-5{ color: #f759ab !important;}
.vurox-color-magenta-6{ color: #eb2f96 !important;}
.vurox-color-magenta-7{ color: #c41d7f !important;}
.vurox-color-magenta-8{ color: #9e1068 !important;}
.vurox-color-magenta-9{ color: #780650 !important;}
.vurox-color-magenta-10{ color: #520339 !important;}

.vurox-color-green-1{ color: #f6ffed !important;}
.vurox-color-green-2{ color: #d9f7be !important;}
.vurox-color-green-3{ color: #b7eb8f !important;}
.vurox-color-green-4{ color: #95de64 !important;}
.vurox-color-green-5{ color: #73d13d !important;}
.vurox-color-green-6{ color: #52c41a !important;}
.vurox-color-green-7{ color: #389e0d !important;}
.vurox-color-green-8{ color: #237804 !important;}
.vurox-color-green-9{ color: #135200 !important;}
.vurox-color-green-10{ color: #092b00 !important;}

.vurox-color-cyan-1{ color: #e6fffb !important;}
.vurox-color-cyan-2{ color: #b5f5ec !important;}
.vurox-color-cyan-3{ color: #87e8de !important;}
.vurox-color-cyan-4{ color: #5cdbd3 !important;}
.vurox-color-cyan-5{ color: #36cfc9 !important;}
.vurox-color-cyan-6{ color: #13c2c2 !important;}
.vurox-color-cyan-7{ color: #08979c !important;}
.vurox-color-cyan-8{ color: #006d75 !important;}
.vurox-color-cyan-9{ color: #00474f !important;}
.vurox-color-cyan-10{ color: #002329 !important;}

.vurox-color-blue-1{ color: #e6f7ff !important;}
.vurox-color-blue-2{ color: #bae7ff !important;}
.vurox-color-blue-3{ color: #91d5ff !important;}
.vurox-color-blue-4{ color: #69c0ff !important;}
.vurox-color-blue-5{ color: #40a9ff !important;}
.vurox-color-blue-6{ color: #1890ff !important;}
.vurox-color-blue-7{ color: #096dd9 !important;}
.vurox-color-blue-8{ color: #0050b3 !important;}
.vurox-color-blue-9{ color: #003a8c !important;}
.vurox-color-blue-10{ color: #002766 !important;}

.vurox-color-gold-1{ color: #fffbe6 !important;}
.vurox-color-gold-2{ color: #fff1b8 !important;}
.vurox-color-gold-3{ color: #ffe58f !important;}
.vurox-color-gold-4{ color: #ffd666 !important;}
.vurox-color-gold-5{ color: #ffc53d !important;}
.vurox-color-gold-6{ color: #faad14 !important;}
.vurox-color-gold-7{ color: #d48806 !important;}
.vurox-color-gold-8{ color: #ad6800 !important;}
.vurox-color-gold-9{ color: #874d00 !important;}
.vurox-color-gold-10{ color: #613400 !important;}

.vurox-color-yellow-1{ color: #feffe6 !important;}
.vurox-color-yellow-2{ color: #ffffb8 !important;}
.vurox-color-yellow-3{ color: #fffb8f !important;}
.vurox-color-yellow-4{ color: #fff566 !important;}
.vurox-color-yellow-5{ color: #ffec3d !important;}
.vurox-color-yellow-6{ color: #fadb14 !important;}
.vurox-color-yellow-7{ color: #d4b106 !important;}
.vurox-color-yellow-8{ color: #ad8b00 !important;}
.vurox-color-yellow-9{ color: #876800 !important;}
.vurox-color-yellow-10{ color: #614700 !important;}

.vurox-color-lime-1{ color: #fcffe6 !important;}
.vurox-color-lime-2{ color: #f4ffb8 !important;}
.vurox-color-lime-3{ color: #eaff8f !important;}
.vurox-color-lime-4{ color: #d3f261 !important;}
.vurox-color-lime-5{ color: #bae637 !important;}
.vurox-color-lime-6{ color: #a0d911 !important;}
.vurox-color-lime-7{ color: #7cb305 !important;}
.vurox-color-lime-8{ color: #5b8c00 !important;}
.vurox-color-lime-9{ color: #3f6600 !important;}
.vurox-color-lime-10{ color: #254000 !important;}

.vurox-color-red-1{ color: #fff1f0 !important;}
.vurox-color-red-2{ color: #ffccc7 !important;}
.vurox-color-red-3{ color: #ffa39e !important;}
.vurox-color-red-4{ color: #ff7875 !important;}
.vurox-color-red-5{ color: #ff4d4f !important;}
.vurox-color-red-6{ color: #f5222d !important;}
.vurox-color-red-7{ color: #cf1322 !important;}
.vurox-color-red-8{ color: #a8071a !important;}
.vurox-color-red-9{ color: #820014 !important;}
.vurox-color-red-10{ color: #5c0011 !important;}

.vurox-color-volcano-1{ color: #fff2e8 !important;}
.vurox-color-volcano-2{ color: #ffd8bf !important;}
.vurox-color-volcano-3{ color: #ffbb96 !important;}
.vurox-color-volcano-4{ color: #ff9c6e !important;}
.vurox-color-volcano-5{ color: #ff7a45 !important;}
.vurox-color-volcano-6{ color: #fa541c !important;}
.vurox-color-volcano-7{ color: #d4380d !important;}
.vurox-color-volcano-8{ color: #ad2102 !important;}
.vurox-color-volcano-9{ color: #871400 !important;}
.vurox-color-volcano-10{ color: #610b00 !important;}

.vurox-color-orange-1{ color: #fff7e6 !important;}
.vurox-color-orange-2{ color: #ffe7ba !important;}
.vurox-color-orange-3{ color: #ffd591 !important;}
.vurox-color-orange-4{ color: #ffc069 !important;}
.vurox-color-orange-5{ color: #ffa940 !important;}
.vurox-color-orange-6{ color: #fa8c16 !important;}
.vurox-color-orange-7{ color: #d46b08 !important;}
.vurox-color-orange-8{ color: #ad4e00 !important;}
.vurox-color-orange-9{ color: #873800 !important;}
.vurox-color-orange-10{ color: #612500 !important;}

.hover-color.bg-geekblue-1:hover{ background-color:darken(color(#f0f5ff), 5%)}
.hover-color.bg-geekblue-2:hover{ background-color:darken(color(#d6e4ff), 5%)}
.hover-color.bg-geekblue-3:hover{ background-color:darken(color(#adc6ff), 5%)}
.hover-color.bg-geekblue-4:hover{ background-color:darken(color(#85a5ff), 5%)}
.hover-color.bg-geekblue-5:hover{ background-color:darken(color(#597ef7), 5%)}
.hover-color.bg-geekblue-6:hover{ background-color:darken(color(#2f54eb), 5%)}
.hover-color.bg-geekblue-7:hover{ background-color:darken(color(#1d39c4), 5%)}
.hover-color.bg-geekblue-8:hover{ background-color:darken(color(#10239e), 5%)}
.hover-color.bg-geekblue-9:hover{ background-color:darken(color(#061178), 5%)}
.hover-color.bg-geekblue-10:hover{ background-color:darken(color(#030852), 5%)}

.hover-color.bg-purple-1:hover{ background-color: darken(color(#f9f0ff), 10%);}
.hover-color.bg-purple-2:hover{ background-color: darken(color(#efdbff), 10%);}
.hover-color.bg-purple-3:hover{ background-color: darken(color(#d3adf7), 10%);}
.hover-color.bg-purple-4:hover{ background-color: darken(color(#b37feb), 10%);}
.hover-color.bg-purple-5:hover{ background-color: darken(color(#9254de), 10%);}
.hover-color.bg-purple-6:hover{ background-color: darken(color(#722ed1), 10%);}
.hover-color.bg-purple-7:hover{ background-color: darken(color(#531dab), 10%);}
.hover-color.bg-purple-8:hover{ background-color: darken(color(#391085), 10%);}
.hover-color.bg-purple-9:hover{ background-color: darken(color(#22075e), 10%);}
.hover-color.bg-purple-10:hover{ background-color: darken(color(#120338), 10%);}

.hover-color.bg-magenta-1:hover{ background-color: darken(color(#fff0f6), 10%);}
.hover-color.bg-magenta-2:hover{ background-color: darken(color(#ffd6e7), 10%);}
.hover-color.bg-magenta-3:hover{ background-color: darken(color(#ffadd2), 10%);}
.hover-color.bg-magenta-4:hover{ background-color: darken(color(#ff85c0), 10%);}
.hover-color.bg-magenta-5:hover{ background-color: darken(color(#f759ab), 10%);}
.hover-color.bg-magenta-6:hover{ background-color: darken(color(#eb2f96), 10%);}
.hover-color.bg-magenta-7:hover{ background-color: darken(color(#c41d7f), 10%);}
.hover-color.bg-magenta-8:hover{ background-color: darken(color(#9e1068), 10%);}
.hover-color.bg-magenta-9:hover{ background-color: darken(color(#780650), 10%);}
.hover-color.bg-magenta-10:hover{ background-color: darken(color(#520339), 10%);}

.hover-color.bg-green-1:hover{ background-color: darken(color(#f6ffed), 10%);}
.hover-color.bg-green-2:hover{ background-color: darken(color(#d9f7be), 10%);}
.hover-color.bg-green-3:hover{ background-color: darken(color(#b7eb8f), 10%);}
.hover-color.bg-green-4:hover{ background-color: darken(color(#95de64), 10%);}
.hover-color.bg-green-5:hover{ background-color: darken(color(#73d13d), 10%);}
.hover-color.bg-green-6:hover{ background-color: darken(color(#52c41a), 10%);}
.hover-color.bg-green-7:hover{ background-color: darken(color(#389e0d), 10%);}
.hover-color.bg-green-8:hover{ background-color: darken(color(#237804), 10%);}
.hover-color.bg-green-9:hover{ background-color: darken(color(#135200), 10%);}
.hover-color.bg-green-10:hover{ background-color: darken(color(#092b00), 10%);}

.hover-color.bg-cyan-1:hover{ background-color: darken(color(#e6fffb), 10%);}
.hover-color.bg-cyan-2:hover{ background-color: darken(color(#b5f5ec), 10%);}
.hover-color.bg-cyan-3:hover{ background-color: darken(color(#87e8de), 10%);}
.hover-color.bg-cyan-4:hover{ background-color: darken(color(#5cdbd3), 10%);}
.hover-color.bg-cyan-5:hover{ background-color: darken(color(#36cfc9), 10%);}
.hover-color.bg-cyan-6:hover{ background-color: darken(color(#13c2c2), 10%);}
.hover-color.bg-cyan-7:hover{ background-color: darken(color(#08979c), 10%);}
.hover-color.bg-cyan-8:hover{ background-color: darken(color(#006d75), 10%);}
.hover-color.bg-cyan-9:hover{ background-color: darken(color(#00474f), 10%);}
.hover-color.bg-cyan-10:hover{ background-color: darken(color(#002329), 10%);}

.hover-color.bg-blue-1:hover{ background-color: darken(color(#e6f7ff), 10%);}
.hover-color.bg-blue-2:hover{ background-color: darken(color(#bae7ff), 10%);}
.hover-color.bg-blue-3:hover{ background-color: darken(color(#91d5ff), 10%);}
.hover-color.bg-blue-4:hover{ background-color: darken(color(#69c0ff), 10%);}
.hover-color.bg-blue-5:hover{ background-color: darken(color(#40a9ff), 10%);}
.hover-color.bg-blue-6:hover{ background-color: darken(color(#1890ff), 10%);}
.hover-color.bg-blue-7:hover{ background-color: darken(color(#096dd9), 10%);}
.hover-color.bg-blue-8:hover{ background-color: darken(color(#0050b3), 10%);}
.hover-color.bg-blue-9:hover{ background-color: darken(color(#003a8c), 10%);}
.hover-color.bg-blue-10:hover{ background-color: darken(color(#002766), 10%);}

.hover-color.bg-gold-1:hover{ background-color: darken(color(#fffbe6), 10%);}
.hover-color.bg-gold-2:hover{ background-color: darken(color(#fff1b8), 10%);}
.hover-color.bg-gold-3:hover{ background-color: darken(color(#ffe58f), 10%);}
.hover-color.bg-gold-4:hover{ background-color: darken(color(#ffd666), 10%);}
.hover-color.bg-gold-5:hover{ background-color: darken(color(#ffc53d), 10%);}
.hover-color.bg-gold-6:hover{ background-color: darken(color(#faad14), 10%);}
.hover-color.bg-gold-7:hover{ background-color: darken(color(#d48806), 10%);}
.hover-color.bg-gold-8:hover{ background-color: darken(color(#ad6800), 10%);}
.hover-color.bg-gold-9:hover{ background-color: darken(color(#874d00), 10%);}
.hover-color.bg-gold-10:hover{ background-color: darken(color(#613400), 10%);}

.hover-color.bg-yellow-1:hover{ background-color: darken(color(#feffe6), 10%);}
.hover-color.bg-yellow-2:hover{ background-color: darken(color(#ffffb8), 10%);}
.hover-color.bg-yellow-3:hover{ background-color: darken(color(#fffb8f), 10%);}
.hover-color.bg-yellow-4:hover{ background-color: darken(color(#fff566), 10%);}
.hover-color.bg-yellow-5:hover{ background-color: darken(color(#ffec3d), 10%);}
.hover-color.bg-yellow-6:hover{ background-color: darken(color(#fadb14), 10%);}
.hover-color.bg-yellow-7:hover{ background-color: darken(color(#d4b106), 10%);}
.hover-color.bg-yellow-8:hover{ background-color: darken(color(#ad8b00), 10%);}
.hover-color.bg-yellow-9:hover{ background-color: darken(color(#876800), 10%);}
.hover-color.bg-yellow-10:hover{ background-color: darken(color(#614700), 10%);}

.hover-color.bg-lime-1:hover{ background-color: darken(color(#fcffe6), 10%);}
.hover-color.bg-lime-2:hover{ background-color: darken(color(#f4ffb8), 10%);}
.hover-color.bg-lime-3:hover{ background-color: darken(color(#eaff8f), 10%);}
.hover-color.bg-lime-4:hover{ background-color: darken(color(#d3f261), 10%);}
.hover-color.bg-lime-5:hover{ background-color: darken(color(#bae637), 10%);}
.hover-color.bg-lime-6:hover{ background-color: darken(color(#a0d911), 10%);}
.hover-color.bg-lime-7:hover{ background-color: darken(color(#7cb305), 10%);}
.hover-color.bg-lime-8:hover{ background-color: darken(color(#5b8c00), 10%);}
.hover-color.bg-lime-9:hover{ background-color: darken(color(#3f6600), 10%);}
.hover-color.bg-lime-10:hover{ background-color: darken(color(#254000), 10%);}

.hover-color.bg-red-1:hover{ background-color: darken(color(#fff1f0), 10%);}
.hover-color.bg-red-2:hover{ background-color: darken(color(#ffccc7), 10%);}
.hover-color.bg-red-3:hover{ background-color: darken(color(#ffa39e), 10%);}
.hover-color.bg-red-4:hover{ background-color: darken(color(#ff7875), 10%);}
.hover-color.bg-red-5:hover{ background-color: darken(color(#ff4d4f), 10%);}
.hover-color.bg-red-6:hover{ background-color: darken(color(#f5222d), 10%);}
.hover-color.bg-red-7:hover{ background-color: darken(color(#cf1322), 10%);}
.hover-color.bg-red-8:hover{ background-color: darken(color(#a8071a), 10%);}
.hover-color.bg-red-9:hover{ background-color: darken(color(#820014), 10%);}
.hover-color.bg-red-10:hover{ background-color: darken(color(#5c0011), 10%);}

.hover-color.bg-volcano-1:hover{ background-color: darken(color(#fff2e8), 10%);}
.hover-color.bg-volcano-2:hover{ background-color: darken(color(#ffd8bf), 10%);}
.hover-color.bg-volcano-3:hover{ background-color: darken(color(#ffbb96), 10%);}
.hover-color.bg-volcano-4:hover{ background-color: darken(color(#ff9c6e), 10%);}
.hover-color.bg-volcano-5:hover{ background-color: darken(color(#ff7a45), 10%);}
.hover-color.bg-volcano-6:hover{ background-color: darken(color(#fa541c), 10%);}
.hover-color.bg-volcano-7:hover{ background-color: darken(color(#d4380d), 10%);}
.hover-color.bg-volcano-8:hover{ background-color: darken(color(#ad2102), 10%);}
.hover-color.bg-volcano-9:hover{ background-color: darken(color(#871400), 10%);}
.hover-color.bg-volcano-10:hover{ background-color: darken(color(#610b00), 10%);}

.hover-color.bg-orange-1:hover{ background-color: darken(color(#fff7e6), 10%);}
.hover-color.bg-orange-2:hover{ background-color: darken(color(#ffe7ba), 10%);}
.hover-color.bg-orange-3:hover{ background-color: darken(color(#ffd591), 10%);}
.hover-color.bg-orange-4:hover{ background-color: darken(color(#ffc069), 10%);}
.hover-color.bg-orange-5:hover{ background-color: darken(color(#ffa940), 10%);}
.hover-color.bg-orange-6:hover{ background-color: darken(color(#fa8c16), 10%);}
.hover-color.bg-orange-7:hover{ background-color: darken(color(#d46b08), 10%);}
.hover-color.bg-orange-8:hover{ background-color: darken(color(#ad4e00), 10%);}
.hover-color.bg-orange-9:hover{ background-color: darken(color(#873800), 10%);}
.hover-color.bg-orange-10:hover{ background-color: darken(color(#612500), 10%);}